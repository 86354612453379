export const useAppStore = defineStore('app', () => {
  const sidebarOpen = ref(false);
  const modalOpen = ref(false);
  const modalName = ref('');
  const isDashboardSearchModalOpen = ref(false);
  const confirmation = ref({
    isOpen: false,
    title: 'Delete Confirmation',
    text: 'Are you sure you want to deactivate your account? All of your data will be permanently removed. This action cannot be undone.',
    confirmText: 'Delete',
    cancelText: 'Cancel',
    confirmButtonClass: 'bg-red-600 text-white hover:bg-red-500',
    onConfirm: () => {
    },
  });
  const leaveMenu = ref(false);
  const selectedNavigation = useCookie<any>('selectedNavigation')
  selectedNavigation.value = selectedNavigation.value?? 0
  const navSectionList = [ //click option doesn't exist
    {id: 0, label: 'SMS Marketing', active: selectedNavigation.value === 0},
    {id: 1, label: 'SMS Hub', active: selectedNavigation.value === 1},
    {id: 2, label: 'Agents', active: selectedNavigation.value === 2}
  ];
  const toggleSidebar = () => {
    sidebarOpen.value = !sidebarOpen.value;
  };

  const routePath = ref()
  useRouter().afterEach((to, from) => {
    routePath.value = to.path
  })
  const navigation = computed(() => {
    if (!routePath.value) {
      routePath.value = useRoute().path
    }

    return [
      [
        {label: 'Dashboard', to: '/', icon: 'i-heroicons-home', active: routePath.value === '/'},
        {
          id: 'campaigns',
          label: 'Campaigns',
          defaultOpen: routePath.value.startsWith('/campaigns'),
          active: routePath.value.startsWith('/campaigns'),
          icon: ICONS.campaigns,
          to: '/campaigns',
          children: [
            {
              label: 'Campaigns',
              to: '/campaigns',
              active: !!(routePath.value.match(/\/campaigns\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i)
                || routePath.value === '/campaigns')
            },
            {
              label: 'Automations',
              to: '/campaigns/automations',
              active: routePath.value.startsWith('/campaigns/automations'),
            },
            {
              label: 'Offers',
              to: '/campaigns/offers',
            },
            {label: 'Reports', to: '/campaigns/reports', active: routePath.value.startsWith('/campaigns/reports')},
          ],
        },
        {
          label: 'Audience',
          icon: 'i-heroicons-users',
          to: '/audience',
          defaultOpen: routePath.value.startsWith('/audience'),
          active: routePath.value.startsWith('/audience'),
          children: [
            {label: 'Contacts', to: '/audience/contacts', active: routePath.value.startsWith('/audience/contacts') },
            {label: 'Upload', to: '/audience/uploads'},
            {label: 'Feeds', to: '/audience/feeds'},
          ],
        },
        {
          label: 'Routing',
          // to: '/sms',
          defaultOpen: routePath.value.startsWith('/sms/routing'),
          active: routePath.value.startsWith('/sms/routing'),
          icon: 'i-heroicons-arrows-right-left',
          children: [
            {label: 'Routes', to: '/sms/routing/routes', active: routePath.value.startsWith('/sms/routing/routes')},
            {label: 'Rates', to: '/sms/routing/rates/rates/countries', active: routePath.value.startsWith('/sms/routing/rates')},
            {label: 'Plans', to: '/sms/routing/plans', active: routePath.value.startsWith('/sms/routing/plans')},
          ],
        },
        {
          label: 'Settings',
          icon: 'i-heroicons-cog',
          defaultOpen: routePath.value.startsWith('/settings'),
          children: [
            {label: 'Custom Fields', to: '/settings/custom-fields'},
            {label: 'URL Shortener', to: '/settings/url-shortener', active: routePath.value.startsWith('/settings/url-shortener')},
          ],
        },
      ],
      [
        {label: 'Dashboard', to: '/hub', icon: 'i-heroicons-home'},
        {
          label: 'Management',
          icon: ICONS.companies,
          children: [
            {label: 'Companies', to: '/hub/companies'},
            {label: 'Financials', to: '/hub/financials'},
            {label: 'Routing plans', to: '/hub/routing/plans'},
            {label: 'Automations', to: '/hub/automations'},
          ]
        },
        {
          label: 'Clients',
          defaultOpen: routePath.value.startsWith('/hub/clients'),
          icon: ICONS.people,
          children: [
            {label: 'Endpoints', to: '/hub/endpoints'},
            {label: 'Rates', to: '/hub/routing/client/rates'},
          ]
        },
        {
          label: 'Vendors',
          defaultOpen: routePath.value.startsWith('/hub/vendors'),
          icon: ICONS.routes,
          children: [
            {label: 'Routes', to: '/hub/routing/routes'},
            {label: 'Rates', to: '/hub/routing/vendor/rates/rates/countries'},
          ]
        },
      ],
      [
        // {label: 'Dashboard', to: '/agents/', icon: 'i-heroicons-home'},
        {
          label: 'Campaigns',
          defaultOpen: routePath.value.startsWith('/agents/campaigns'),
          icon: ICONS.campaigns,
          currentUrl: false,
          to: '/agents/campaigns',
        },
        { label: 'Agents',
          icon: ICONS.tools,
          to: '/agents/agents',
        children: [
          {label: 'Agents', to: '/agents/agents', active: routePath.value.startsWith('/agents/agents')},
          {
            label: 'Tools',
            currentUrl: false,
            to: '/agents/tools',
          },
          {
            label: 'Post processing',
            to: '/agents/post-processing',
          },
        ]},
        {
          label: 'Audience',
          icon: 'i-heroicons-users',
          to: '/audience',
          defaultOpen: routePath.value.startsWith('/audience'),
          active: routePath.value.startsWith('/audience'),
          children: [
            {label: 'Contacts', to: '/audience/contacts', active: routePath.value.startsWith('/audience/contacts') },
            {label: 'Upload', to: '/audience/uploads'},
            {label: 'Feeds', to: '/audience/feeds'},
          ],
        },
        {
          label: 'Settings',
          icon: ICONS.settings,
          to: '/agents/settings/accounts',
          children: [
            {label: 'Accounts', to: '/agents/settings/accounts'},
            {label: 'Numbers', to: '/agents/settings/numbers'},
          ]
        }
      ]
    ][selectedNavigation.value!]
  });

  return {
    sidebarOpen,
    modalOpen,
    modalName,
    navigation,
    isDashboardSearchModalOpen,
    selectedNavigation,
    leaveMenu,
    navSectionList,
    confirmation,
    toggleSidebar,
  };
});
